var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "1/2", pageTitle: "Add New Supplier" } },
    [
      _c(
        "vx-card",
        [
          _c(
            "vs-tabs",
            { staticClass: "tabs-shadow-none" },
            [
              _c(
                "vs-tab",
                {
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-settings",
                    label: "General Info",
                  },
                },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:25",
                        expression: "'required|max:25'",
                      },
                    ],
                    staticClass: "w-full mt-4",
                    attrs: { label: "Supplier Name*", name: "Supplier Name" },
                    model: {
                      value: _vm.form.supplierName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "supplierName", $$v)
                      },
                      expression: "form.supplierName",
                    },
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("Supplier Name"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c("label", { staticClass: "text-sm" }, [
                        _vm._v("Product Type*"),
                      ]),
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          value: _vm.selectedProductType,
                          "append-to-body": "",
                          options: _vm.productTypeOptions,
                          name: "Product Type",
                        },
                        on: { input: _vm.setSelectedProductType },
                      }),
                      _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v(_vm._s(_vm.errors.first("Product Type"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c("label", { staticClass: "text-sm" }, [
                        _vm._v("API Type*"),
                      ]),
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          value: _vm.selectedApiType,
                          "append-to-body": "",
                          options: _vm.apiTypeOptions,
                          name: "API Type",
                        },
                        on: { input: _vm.setSelectedApiType },
                      }),
                      _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v(_vm._s(_vm.errors.first("API Type"))),
                      ]),
                    ],
                    1
                  ),
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:500",
                        expression: "'max:500'",
                      },
                    ],
                    staticClass: "w-full mt-4",
                    attrs: { label: "Endpoint URL", name: "Endpoint URL" },
                    model: {
                      value: _vm.form.endpointUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "endpointUrl", $$v)
                      },
                      expression: "form.endpointUrl",
                    },
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("Endpoint URL"))),
                  ]),
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:500",
                        expression: "'max:500'",
                      },
                    ],
                    staticClass: "w-full mt-4",
                    attrs: { label: "Docs URL", name: "Docs URL" },
                    model: {
                      value: _vm.form.docsUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "docsUrl", $$v)
                      },
                      expression: "form.docsUrl",
                    },
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("Docs URL"))),
                  ]),
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:500",
                        expression: "'max:500'",
                      },
                    ],
                    staticClass: "w-full mt-4",
                    attrs: { label: "Status URL", name: "Status URL" },
                    model: {
                      value: _vm.form.statusUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "statusUrl", $$v)
                      },
                      expression: "form.statusUrl",
                    },
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("Status URL"))),
                  ]),
                ],
                1
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-users",
                    label: "Contacts",
                  },
                },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:500",
                        expression: "'max:500'",
                      },
                    ],
                    staticClass: "w-full mt-4",
                    attrs: { label: "Tech Contact", name: "Tech Contact" },
                    model: {
                      value: _vm.form.techContact,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "techContact", $$v)
                      },
                      expression: "form.techContact",
                    },
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("Tech Contact"))),
                  ]),
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:500",
                        expression: "'max:500'",
                      },
                    ],
                    staticClass: "w-full mt-4",
                    attrs: {
                      label: "Account Contact",
                      name: "Account Contact",
                    },
                    model: {
                      value: _vm.form.accountContact,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "accountContact", $$v)
                      },
                      expression: "form.accountContact",
                    },
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("Account Contact"))),
                  ]),
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:500",
                        expression: "'max:500'",
                      },
                    ],
                    staticClass: "w-full mt-4",
                    attrs: {
                      label: "Post Book Contact",
                      name: "Post Book Contact",
                    },
                    model: {
                      value: _vm.form.postBookContact,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "postBookContact", $$v)
                      },
                      expression: "form.postBookContact",
                    },
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("Post Book Contact"))),
                  ]),
                ],
                1
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-lock",
                    label: "Credentials",
                  },
                },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:25",
                        expression: "'max:25'",
                      },
                    ],
                    staticClass: "w-full mt-4",
                    attrs: { label: "Username", name: "Username" },
                    model: {
                      value: _vm.form.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "username", $$v)
                      },
                      expression: "form.username",
                    },
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("Tech Contact"))),
                  ]),
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:25",
                        expression: "'max:25'",
                      },
                    ],
                    staticClass: "w-full mt-4",
                    attrs: {
                      type: "password",
                      label: "Password",
                      name: "Password",
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "lg:float-left mt-4" }, [
            _c("span", { staticClass: "text-sm text-danger" }, [
              _vm._v("*Required Field"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-end" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "mt-4 mr-4",
                  attrs: { type: "border", color: "danger" },
                  on: { click: _vm.handleCancel },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "vs-button",
                { staticClass: "mt-4", on: { click: _vm.handleSubmit } },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }